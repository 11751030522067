import './index.css'; 
import React from 'react';
import { createRoot } from 'react-dom/client'; 
import App from './App';
import { DataProvider } from './context/DataContext';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <DataProvider>
    <App />
  </DataProvider>
);
